<script setup lang="ts">
import {
  useExpiryChart,
  createDashboardPortfolioURI,
  DashboardQuery,
} from '@dashboard'
import { Product } from '~/composables/useAPI/types'

type ShowBy =
  | DashboardQuery.EXPIRY_PROFILE_BY_RENT
  | DashboardQuery.EXPIRY_PROFILE_BY_AREA

const { $can } = useNuxtApp()

const showBy = ref<ShowBy>(
  $can('product', Product.LEASE_MANAGER)
    ? DashboardQuery.EXPIRY_PROFILE_BY_RENT
    : DashboardQuery.EXPIRY_PROFILE_BY_AREA,
)

const showByText = {
  [DashboardQuery.EXPIRY_PROFILE_BY_AREA]: 'area',
  [DashboardQuery.EXPIRY_PROFILE_BY_RENT]: 'rent',
} as Record<ShowBy, string>

/* === FETCH DATA === */
const companyId = getCurrentDivisionId()
const { data, isPending } = useApiDashboard(companyId, showBy)
const isEmpty = computed(
  () => Object.keys(data.value?.results ?? {}).length === 0,
)
/* === FETCH DATA === */

const options = useExpiryChart(
  computed(() => data.value?.results ?? {}),
  showBy,
  computed(() => data.value?.templateId),
)
</script>

<template>
  <DashboardWidget
    class="pb-0"
    header="Lease Expiry Profile"
    :subheader="`(by ${showByText[showBy]})`"
    :loading="isPending"
    :is-empty="isEmpty"
  >
    <template #secondaryTitle>
      <DashboardToggleRentArea
        v-model="showBy"
        :rent-value="DashboardQuery.EXPIRY_PROFILE_BY_RENT"
        :area-value="DashboardQuery.EXPIRY_PROFILE_BY_AREA"
      />
    </template>

    <template #additional>
      <DashboardMissingData
        v-if="data?.missingData"
        v-bind="data.missingData"
      />
    </template>

    <highchart ref="chartRef" :options="options" class="h-full w-full" />
  </DashboardWidget>
</template>

import type {
  Options as HighchartsOptions,
  SeriesColumnOptions,
} from 'highcharts'
import type { ComputedRef, Ref } from 'vue'
import {
  getYearsInRange,
  convertDashboardExpiryProfileResultToChart,
  createDashboardReportURI,
} from '../utils'
import type {
  IDashboardResultExpiryProfile,
  IDashboardResultExpiryProfileByArea,
  IDashboardResultExpiryProfileByRent,
} from '~/composables/useAPI/useApiDashboard'
import type { ISearchPropertyLeasePortfolioRequest } from '@portfolio'
import { DashboardQuery } from '@dashboard'

export const useExpiryChart = (
  result: Ref<
    | IDashboardResultExpiryProfile
    | IDashboardResultExpiryProfileByArea
    | IDashboardResultExpiryProfileByRent
  >,
  type: MaybeRefOrGetter<
    | DashboardQuery.EXPIRY_PROFILE
    | DashboardQuery.EXPIRY_PROFILE_BY_AREA
    | DashboardQuery.EXPIRY_PROFILE_BY_RENT
  >,
  templateId: ComputedRef<string | undefined>,
) => {
  const router = useRouter()
  const companyId = getCurrentDivisionId()

  /* === RANGE === */
  const startYear = new Date().getFullYear()
  const endYear = startYear + 10
  /* === RANGE === */

  const mapper = {
    [DashboardQuery.EXPIRY_PROFILE]: {
      by: 'number of leases',
      tooltip: {
        singular: ' lease',
        plural: ' leases',
      },
      formatter: (v: any) => v,
    },
    [DashboardQuery.EXPIRY_PROFILE_BY_AREA]: {
      by: 'area',
      tooltip: {
        singular: '% of area',
        plural: '% of area',
      },
      formatter: (v: any) => v + '%',
    },
    [DashboardQuery.EXPIRY_PROFILE_BY_RENT]: {
      by: 'rent',
      tooltip: {
        singular: '% of rent',
        plural: '% of rent',
      },
      formatter: (v: any) => v + '%',
    },
  }

  function tooltip(data: any) {
    const location = abbreviationToFullState(data.series.name)
    const value = new Intl.NumberFormat().format(data.y)
    const total = new Intl.NumberFormat().format(data.point?.stackTotal)
    return `
          <b>Leases expiring in ${data.key}</b>
          <br/>${location}:<b>${value}${
            total === '1'
              ? mapper[toValue(type)].tooltip.singular
              : mapper[toValue(type)].tooltip.plural
          }</b>`
  }

  return computed<HighchartsOptions>(() => {
    return {
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        events: {
          load() {
            const xAxis = this.xAxis[0]
            const yAxis = this.yAxis[0]

            // Add label at the bottom-left corner (near the X and Y axis intersection)
            this.renderer
              .text(
                'CY:',
                xAxis.left - 25,
                yAxis.toPixels(0) + this.spacing[2] + 12,
              )
              .attr({
                zIndex: 5,
              })
              .css({
                color: '#BFBFBF',
                fontSize: '12px',
              })
              .add()
          },
        },
      },
      credits: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
      xAxis: {
        categories: getYearsInRange(result.value, startYear, endYear),
        labels: {
          style: {
            color: '#BFBFBF',
          },
        },
      },
      yAxis: {
        allowDecimals: false,
        min: 0,
        gridLineColor: '#444',
        // tickInterval: 40,
        gridLineWidth: 1,
        labels: {
          style: {
            color: '#BFBFBF',
          },
          formatter: function () {
            return mapper[toValue(type)].formatter(this.value)
          },
        },
        title: {
          text: '',
          style: {
            color: '#BFBFBF',
          },
        },
      },

      tooltip: {
        formatter: function () {
          return tooltip(this)
        },
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          pointWidth: 20,
        },
        series: {
          cursor: 'pointer',
          point: {
            events: {
              click() {
                if ('link' in this) {
                  redirectToReport(
                    this.link as ISearchPropertyLeasePortfolioRequest,
                    templateId.value,
                  )

                  useAnalytics({
                    name: 'Chart click',
                    showDivision: true,
                    data: {
                      Origin: `Lease expiry profile (by ${mapper[toValue(type)].by})`,
                    },
                  })
                }
              },
            },
          },
        },
      },
      legend: {
        itemHoverStyle: {
          color: '#D8FC59',
        },
        itemStyle: {
          color: 'white',
        },
      },

      series: convertDashboardExpiryProfileResultToChart(
        result.value,
        startYear,
        endYear,
        toValue(type),
      ).map<SeriesColumnOptions>((item) => {
        return {
          ...item,
          name: fullStateToAbbreviation(item.name),
          type: 'column',
          borderWidth: 0,
          borderRadius: 6,
          events: {
            legendItemClick(e) {
              // const options = e.target.options
              // if ('link' in options) {
              //   redirectToReport(
              //     options.link as ISearchPropertyLeasePortfolioRequest,
              //   )
              // }
              // return false
            },
          },
        }
      }),
    }
  })

  function redirectToReport(
    link: ISearchPropertyLeasePortfolioRequest | undefined,
    templateId?: string,
  ) {
    const path = createDashboardReportURI(companyId, link, templateId)
    if (path) {
      router.push(path)
    }
  }
}
